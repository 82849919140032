
import { Box, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SectionBox } from '../components/section-box/section-box';
import { SectionTitle } from '../components/section-title/section-title';

export const CompanyDetailsPageHU: React.FC<{  }> = (props) => {

  const {t} = useTranslation('common');

  return <>
    <Box>
      <Stack spacing={8} >
        <SectionBox boxParams={{style: {padding: "0 100px"}}}>
          <SectionTitle title={t('company-details.title')} />
          <Box marginTop={3} marginBottom={3}>
            CLICKnCRUISE Korlátolt Felelősségű Társaság<br/>
            Székhely: 9141, Ikrény Rét köz 2. B. Ép.<br/>
            Email: ahoy@clickncruise.hu<br/>
            Telefon: +36 20 492 9523<br/>
            Cégjegyzékszám: 08-09-033950<br/>
            Adószám: 27547111-1-08<br/>
            Nyilvántartási szám: U-001970<br/>
            Tárhelyszolgáltató adatai: Amazon Web Services<br/>
          </Box>
        </SectionBox>
      </Stack>
    </Box>
  </>
}
CompanyDetailsPageHU.displayName = 'CompanyDetailsPageHU';