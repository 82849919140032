import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography, useMediaQuery, TextField, Stack, Alert } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTracking } from '../../hooks/useTracking';
import { BookingState } from '../../models/BookingState';
import { ColorButton } from '../color-button/color-button';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import './favorite-mail-dialog.scss';
import { CruiseDepartureDate } from '../../services/search';
import Snackbar from '@mui/material/Snackbar';
import { useCheckoutApi } from '../../contexts/checkout-api.context';
import TiktokPixel from 'tiktok-pixel';

export const FavoriteMailDialog: React.FC<{
  data: BookingState;
  date?: CruiseDepartureDate;
  handleChange?: () => void;
  iconOnly?: boolean;
}> = (props) => {
  const { trackEvent } = useTracking();
  const listId = localStorage.getItem('list_id');

  // Translation
  const { t } = useTranslation('common');

  // Theme
  const theme = useTheme();
  const isLayoutMedium = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  const [isDialogOpened, setDialogOpened] = useState<boolean>(false);
  const [isSaveSuccesful, setSaveSuccesful] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const { checkoutApi } = useCheckoutApi();

  const handleDialogOpen = () => {
    setDialogOpened(true);
  };

  const handleDialogClose = () => {
    setDialogOpened(false);
  };

  const handleEmailFieldChange = (event: any) => {
    const value = event.target.value;
    setEmail(value);
  };

  const handleSnackbarClose = () => {
    setSaveSuccesful(false);
  };

  const successMessage = (
    <Snackbar
      open={isSaveSuccesful}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleSnackbarClose} severity="success" variant="filled">
        <Typography variant="body1" textAlign="left" fontSize={14} color="#fff">
          {t('booking.favorite.success-message')}
        </Typography>
      </Alert>
    </Snackbar>
  );

  const handleSubmit = (event: any) => {
    if (props.data.bookingDetails?.cruiseGroupId) {
      const d = props.date ? props.date : props.data.departureDate;

      if (d) {
        if (listId) {
          checkoutApi
            .createOffer({
              listId,
              message: email,
              state: props.data
            })
            .then((response) => {
              handleDialogClose();
            });
        } else if (email) {
          trackEvent('favorite_email_sent', { cruise_id: d.id });
          checkoutApi
            .createFavorite({
              email: email,
              cruiseGroupId: props.data.bookingDetails?.cruiseGroupId,
              cruiseId: d.id
            })
            .then((response) => {
              setSaveSuccesful(true);
              if (props.handleChange) {
                props.handleChange();
              }
              handleDialogClose();
            });

          if (__CONFIG__.tracking.tiktokPixelId) {
            TiktokPixel.track('AddToWishlist', {
              content_type: 'product',
              quantity: 1,
              description: 'Cruise',
              content_id: props.data.bookingDetails?.cruiseGroupId,
              currency: __CONFIG__.currency.default,
              value: props.data.pricePerPerson
            });
          }
        }
      }
    }
  };

  return (
    <Box className="favorite-mail-dialog-container">
      {successMessage}
      <ColorButton
        className={props.iconOnly ? 'button-icon-only' : ''}
        label={props.iconOnly ? '' : t('booking.favorite.save-button-label')}
        color={'secondary'}
        variant={'outlined'}
        startIcon={<FavoriteBorderIcon color={listId ? 'primary' : 'error'} />}
        onClick={(e) => {
          if (!listId) {
            trackEvent('favorite_dialog_opened');
          }
          handleDialogOpen();
        }}
        fullWidth
      />
      <Dialog
        className="favorite-mail-dialog"
        open={isDialogOpened}
        onClose={handleDialogClose}
        fullScreen={isLayoutMedium}
      >
        {!listId && <DialogTitle>
          {t('booking.favorite.dialog.title')}
          <IconButton
            aria-label="Bezar"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>}
        <DialogContent style={{ minWidth: isLayoutMedium ? '' : '480px' }}>
          <Stack gap={4}>
            {!listId && <Typography variant="body1" textAlign="left" marginTop="8px">
              {t('booking.favorite.dialog.content')}
            </Typography>}
            <Stack gap={2} alignItems={'center'}>
              <TextField
                id="field-email"
                label={null}
                type="email"
                variant="outlined"
                placeholder={listId ? 'Message' : t('booking.favorite.dialog.email-placeholder')}
                onChange={handleEmailFieldChange}
                style={{ width: '100%', minWidth: isLayoutMedium ? '' : '250px' }}
                required={true}
                inputProps={{ autoComplete: 'email' }}
              />
              <ColorButton label={listId ? 'Add' : t('booking.favorite.dialog.submit-button-label')} onClick={handleSubmit} />
            </Stack>
            {!listId && <Typography variant="body2" textAlign="center" marginTop="8px">
              {t('booking.favorite.dialog.footer')}
            </Typography>}
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
