import { useJitsu } from '@jitsu/react';
import { EventPayload } from '@jitsu/sdk-js';
import Cookies from 'js-cookie';

const ANONYMOUS_ID_COOKIE_KEY = '__eventn_id';

export const useTracking = () => {
  const { track, id } = useJitsu();
  return {
    trackEvent: (eventName: string, eventData?: EventPayload) => {
      track(eventName, eventData);
    },
    getAnonymousId: () => {
      return Cookies.get(ANONYMOUS_ID_COOKIE_KEY);
    },
    login: (userId: string) => {
      id({id: userId});
    },
    logout: () => {
      id({});
    },
  };
};
