import { useGrowthBook } from '@growthbook/growthbook-react';
import { useJitsu } from '@jitsu/react';
import { Box } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import TiktokPixel from 'tiktok-pixel';
import { useTracking } from '../../hooks/useTracking';
import { ColorButton } from '../color-button/color-button';
import './tracking-with-consent.scss';

const GA_MEASUREMENT_ID = __CONFIG__.tracking.gaMeasurementId;
const GOOGLE_ADS_TAG_ID = __CONFIG__.tracking.googleAdsTagId;
const FB_PIXEL_ID = __CONFIG__.tracking.fbPixelId;
const HOTJAR_HJID = __CONFIG__.tracking.hotjarHjid;
const HOTJAR_HJSV = __CONFIG__.tracking.hotjarHjsv;
const TIKTOK_PIXEL_ID = __CONFIG__.tracking.tiktokPixelId;

export const TrackingWithConsent: React.FC<{}> = (props) => {
  const { t } = useTranslation('common');
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [initialized, setInitialized] = useState(false);
  const [consented, setConsented] = useState(!!getCookieConsentValue());
  const { trackPageView } = useJitsu();
  const growthbook = useGrowthBook();
  const { getAnonymousId } = useTracking();

  const onCookiePolicyAccept = useCallback(() => {
    if (GA_MEASUREMENT_ID) {
      ReactGA.gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted'
      });
    }

    if (FB_PIXEL_ID) {
      ReactPixel.grantConsent();
    }

    if (HOTJAR_HJID) {
      hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);
    }

    if (TIKTOK_PIXEL_ID) {
      TiktokPixel.init(TIKTOK_PIXEL_ID);
    }

    setConsented(true);
  }, []);

  useEffect(() => {
    if (GA_MEASUREMENT_ID) {
      ReactGA.initialize(GA_MEASUREMENT_ID);
      if (!consented) {
        ReactGA.gtag('consent', 'default', {
          ad_storage: 'denied',
          analytics_storage: 'denied'
        });
      }

      if (GOOGLE_ADS_TAG_ID) {
        ReactGA.gtag('config', GOOGLE_ADS_TAG_ID);
      }
    }

    if (FB_PIXEL_ID) {
      ReactPixel.init(FB_PIXEL_ID);
      if (!consented) {
        ReactPixel.revokeConsent();
      }
    }

    if (HOTJAR_HJID && consented) {
      hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);
    }

    if (TIKTOK_PIXEL_ID && consented) {
      TiktokPixel.init(TIKTOK_PIXEL_ID);
    }

    if (growthbook) {
      const isBetaParam = searchParams.get('beta');
      if (isBetaParam === 'true' || isBetaParam === 'false') {
        localStorage.setItem('beta', isBetaParam);
      }

      growthbook.loadFeatures().then(() => {
        growthbook.setAttributes({
          user_anonymous_id: getAnonymousId(),
          market: __CONFIG__.market,
          beta: localStorage.getItem('beta') === 'true'
        });
      });
    }

    const listIdParam = searchParams.get('list_id');
    if (listIdParam === '' || listIdParam) {
      localStorage.setItem('list_id', listIdParam);
    }

    setInitialized(true);
  }, []);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    trackPageView();

    const page = location.pathname + location.search;

    if (GA_MEASUREMENT_ID) {
      ReactGA.send({
        hitType: 'pageview',
        page
      });
    }

    if (FB_PIXEL_ID) {
      ReactPixel.pageView();
    }

    if (HOTJAR_HJID && consented) {
      hotjar.stateChange(page);
    }

    if (TIKTOK_PIXEL_ID && consented) {
      TiktokPixel.pageView();
    }
  }, [initialized, consented, location]);

  return (
    <Box className="tracking-with-consent">
      <CookieConsent
        onAccept={onCookiePolicyAccept}
        ButtonComponent={ColorButton}
        customButtonProps={{ label: t('tracking.cookie-consent-accept') }}
        disableButtonStyles={true}
        buttonClasses="tracking-with-consent-accept-button"
        containerClasses="tracking-with-consent-container"
      >
        <Trans
          t={t}
          i18nKey="tracking.cookie-consent-text"
          components={{
            ppLink: <Link style={{ fontWeight: '600', color: '#fff' }} to="/privacy-policy" target="_blank" />
          }}
        />
      </CookieConsent>
    </Box>
  );
};
