import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  debounce,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDiscount } from '../../hooks/useDiscount';
import { BookingCruiseCabin, BookingGuestState, BookingState, GuestType } from '../../models/BookingState';
import { FilterOptions, GuestFilterOption } from '../../models/FilterOptions';
import { CruiseService } from '../../services/search';
import { BookingDepartureCallbackDialog } from '../booking-departure-callback-dialog/booking-departure-callback-dialog';
import { FavoriteMailDialog } from '../favorite-mail-dialog/favorite-mail-dialog';
import { GuestsSelectItem } from '../input-guests-select-item/input-guests-select-item';
import { Price } from '../price/price';
import './booking-offer-summary.scss';

const DiscountBox: React.FC<{
  sharedUseDiscountHook: ReturnType<typeof useDiscount>;
}> = (props) => {
  const { t } = useTranslation('common');
  const { applyDiscount, discountIsLoading } = props.sharedUseDiscountHook;
  const debouncedApplyDiscount = useCallback(
    debounce((d) => applyDiscount(d), 750),
    []
  );

  return (
    <Box mb={2}>
      <Typography variant="h3" textAlign="left" style={{ fontSize: '16px' }}>
        {t('offer-summary.discount-box.title')}
      </Typography>
      <TextField
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          debouncedApplyDiscount(e.target.value);
        }}
        sx={{ width: '100%', textAlign: 'start' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{discountIsLoading && <CircularProgress size={20} />}</InputAdornment>
          )
        }}
      />
    </Box>
  );
};

export const BookingOfferSummary: React.FC<{
  bookingState: BookingState;
  fullWidth?: boolean;
  readonly?: boolean;
  updateBooking: (b: BookingState) => void;
  onGuestCountChanged?: () => void;
  discountsEnabled?: boolean;
}> = (props) => {
  // Translation
  const { t, i18n } = useTranslation('common');

  // Theme
  const theme = useTheme();
  const isLayoutMedium = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  // States
  const { bookingState, fullWidth } = props;
  const [selectedOptions, setSelectedOptions] = useState<number[]>();

  const sharedUseDiscountHook = useDiscount(props.bookingState);
  const calculateWithDiscount = !!props.discountsEnabled && sharedUseDiscountHook.hasDiscount;

  // Init guest count, if booking state was loaded from storage
  FilterOptions.guests.adult.count = bookingState.numberOfGuests.adult;
  FilterOptions.guests.child.count = bookingState.numberOfGuests.child;

  // Guest selector
  const guestPlusClick = (event: any) => {
    let o = FilterOptions.guests.get(event.target.dataset.optionValue);
    if (o) {
      o.count++;
      handleChange(event, o.count);
    }
  };

  const guestMinusClick = (event: any) => {
    let minValue = event.target.dataset.optionValue === 'adult' ? 1 : 0;
    let o = FilterOptions.guests.get(event.target.dataset.optionValue);
    if (o && o.count > minValue) {
      o.count--;
      handleChange(event, o.count);
    }
  };

  const handleChange = (event: any, count: number) => {
    let values: number[] = [FilterOptions.guests.adult.count, FilterOptions.guests.child.count];
    bookingState.numberOfGuests.setValue(event.target.dataset.optionValue, count);
    setSelectedOptions(values);

    const currentType = event.target.dataset.optionValue as GuestType;
    const modArray = bookingState.guestsState.getGuestListByType(currentType);
    if (count > modArray.length) {
      bookingState.guestsState.guestList.push(new BookingGuestState({ type: currentType }));
    } else {
      bookingState.guestsState.removeLasGuesttOfType(currentType);
    }

    props.updateBooking(bookingState);
    if (props.onGuestCountChanged) {
      props.onGuestCountChanged();
    }
  };

  const getColumnSize = (): number => {
    return fullWidth ? (!isLayoutMedium ? 12 : 4) : 12;
  };

  const Title = () => {
    if (!isLayoutMedium) {
      return (
        <>
          <Typography variant="h2" textAlign="center" marginBottom="8px">
            {t('offer-summary.title')}
          </Typography>
        </>
      );
    } else {
      return (
        <>
          <Typography variant="h3" textAlign="left" marginBottom="8px">
            {t('offer-summary.title')}
          </Typography>
        </>
      );
    }
  };

  const [callbackSuccessful, setCallbackSuccessful] = useState(false);

  const handleCallbackRequest = () => {
    setCallbackSuccessful(true);
  };

  const ArriveByWarningBox = () => {
    return (
      <Alert severity="warning" variant="filled" className="arrive-by-warning-box">
        {t('offer-summary.arrive-by-warning')}
      </Alert>
    );
  };

  const InfoBox = (args: { cabinType: BookingCruiseCabin }) => {
    return (
      <Alert severity="info" variant="filled" className="booking-info-box" icon={false}>
        <AlertTitle>
          <Typography variant="h3" textAlign="left" marginBottom="15px" color="#fff">
            {t('offer-summary.infobox.title')}
          </Typography>
        </AlertTitle>
        <Typography variant="body1" textAlign="left" fontSize={14} color="#fff">
          {t('offer-summary.infobox.description')}
        </Typography>
        <Box mt={2}>
          <BookingDepartureCallbackDialog
            data={props.bookingState}
            cabinId={props.bookingState.cabinType?.id}
            handleChange={handleCallbackRequest}
          />
        </Box>
      </Alert>
    );
  };

  const FavoriteBox = () => {
    return (
      <Alert severity="success" variant="outlined" className="booking-info-box" icon={false}>
        <AlertTitle>
          <Typography variant="h3" textAlign="left" marginBottom="15px">
            {t('booking.favorite.dialog.title')}
          </Typography>
        </AlertTitle>
        <Typography variant="body1" textAlign="left" fontSize={14}>
          {t('booking.favorite.dialog.short-content')}
        </Typography>
        <Box mt={2}>
          <FavoriteMailDialog data={props.bookingState} />
        </Box>
      </Alert>
    );
  };

  const PriceBox = (args: { label: string; mode: 'deposit' | 'remaining'; date: string }) => {
    return (
      <>
        <Stack className="price-box" spacing={2}>
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="h3" textAlign="left" style={{ fontSize: 14 }}>
                {args.label}:
              </Typography>
            </Grid>
            <Grid item xs={8} className="price-detail">
              <Price
                mainPrice={
                  args.mode === 'deposit'
                    ? bookingState.getDepositPrice()
                    : bookingState.getRemainingPrice(calculateWithDiscount)
                }
                localPrice={
                  args.mode === 'deposit'
                    ? bookingState.getDepositPriceLocalCcy()
                    : bookingState.getRemainingPriceLocalCcy(calculateWithDiscount)
                }
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="body1" style={{ fontSize: 12, fontWeight: 400 }}>
                {t('offer-summary.price-payment-date')}:
              </Typography>
            </Grid>
            {bookingState.departureDate && (
              <Grid item>
                <Typography variant="body1" textAlign="right" style={{ fontSize: 12, fontWeight: 600 }}>
                  {args.date}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Stack>
      </>
    );
  };

  const shouldPayDeposit = bookingState.shouldPayDeposit();
  const depositPaymentDate = bookingState.getDepositPaymentDate();
  const lastPaymentDate = bookingState.getLastPaymentDate();

  const departure = bookingState.bookingDetails?.portsOfCall[0];
  const arrival = bookingState.bookingDetails?.portsOfCall[bookingState.bookingDetails?.portsOfCall.length - 1];

  const departureTime = bookingState.bookingDetails?.itinerary?.[0].departureTime || '';
  const arrivalTime =
    bookingState.bookingDetails?.itinerary?.[bookingState.bookingDetails?.itinerary?.length - 1].arrivalTime || '';

  return (
    <>
      <Box>
        {!fullWidth && <Title />}
        <Grid
          className="booking-offer-summary"
          rowSpacing={1}
          columnGap={fullWidth ? 6 : 0}
          container
          style={{ border: fullWidth ? 'none' : '', textAlign: fullWidth ? 'left' : 'inherit' }}
          mb={2}
        >
          {bookingState.bookingDetails && bookingState.bookingDetails && (
            <>
              <Grid item xs={getColumnSize()} order={1}>
                <Box style={{ marginTop: fullWidth ? '25px' : '' }}>
                  {t(`search.filters.cruiseLine.options.${bookingState.bookingDetails.cruiseLine}`)} -{' '}
                  {bookingState.bookingDetails.shipName}
                </Box>
              </Grid>
              <Grid item xs={getColumnSize()} order={fullWidth ? 3 : 2}>
                <Stack className="offer-row" spacing={0}>
                  <Box className="label">
                    {t(`search.filters.portsOfCall.options.${departure}`, departure)}
                    {departure !== arrival && <> - {t(`search.filters.portsOfCall.options.${arrival}`, arrival)}</>}
                  </Box>
                  <Box className="value">
                    {t('offer-summary.number-of-days', { count: bookingState.bookingDetails.cruiseLength + 1 })} -{' '}
                    {t('offer-summary.number-of-nights', { count: bookingState.bookingDetails.cruiseLength })}
                  </Box>
                </Stack>
              </Grid>
            </>
          )}
          <Grid item xs={getColumnSize()} order={fullWidth ? 4 : 3}>
            <Box className="offer-row">
              {FilterOptions.guests.getAll().map((o: GuestFilterOption, index: number) => (
                <div key={index}>
                  <GuestsSelectItem
                    option={o}
                    plusClick={guestPlusClick}
                    minusClick={guestMinusClick}
                    readonly={props.readonly}
                  />
                </div>
              ))}
            </Box>
          </Grid>

          {bookingState.departureDate && (
            <Grid item xs={getColumnSize()} order={4}>
              <Grid container className="offer-row">
                <Grid item xs={6} md={6}>
                  <Stack>
                    <Box textTransform="capitalize">{t('labels.departure')}</Box>
                    <Box>
                      <strong>
                        {bookingState.departureDate.fromDate.toLocaleDateString(i18n.language, {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric'
                        })}
                      </strong>
                    </Box>
                    <Box>
                      {bookingState.departureDate.fromDate.toLocaleDateString(i18n.language, { weekday: 'long' })}{' '}
                      {departureTime}
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Stack>
                    <Box textTransform="capitalize">{t('labels.arrival')}</Box>
                    <Box>
                      <strong>
                        {bookingState.departureDate.toDate.toLocaleDateString(i18n.language, {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric'
                        })}
                      </strong>
                    </Box>
                    <Box>
                      {bookingState.departureDate.toDate.toLocaleDateString(i18n.language, { weekday: 'long' })}{' '}
                      {arrivalTime}
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <ArriveByWarningBox />
                </Grid>
              </Grid>
            </Grid>
          )}

          {bookingState.cabinType && (
            <Grid item xs={getColumnSize()} order={fullWidth ? 2 : 5}>
              <Stack className="offer-row" style={{ borderTop: fullWidth && isLayoutMedium ? 'none' : '' }}>
                <Box className="label">{t('search.filters.cabinCategory.label')}</Box>
                <Box className="value">{t(`${bookingState.cabinType.category}`)}</Box>
              </Stack>
            </Grid>
          )}

          {bookingState.services && bookingState.services.length > 0 && (
            <Grid item xs={getColumnSize()} order={fullWidth ? 2 : 5}>
              <Stack className="offer-row" style={{ borderTop: fullWidth && isLayoutMedium ? 'none' : '' }}>
                <Box className="label">{t('booking.services.title')}</Box>
                {bookingState.services.map((s: CruiseService, index: number) => (
                  <Box key={index} className="value">
                    {t(s.name)}
                  </Box>
                ))}
              </Stack>
            </Grid>
          )}

          {bookingState.departureDate && (
            <Grid item xs={12} order={6} style={{ textAlign: 'right' }}>
              <Stack direction={fullWidth ? 'row' : 'column'} justifyContent="space-between" className="offer-row">
                {props.discountsEnabled && <DiscountBox sharedUseDiscountHook={sharedUseDiscountHook} />}
                {!fullWidth && (
                  <Typography variant="h3" textAlign="left" style={{ fontSize: 16, textTransform: 'uppercase' }}>
                    {t('offer-summary.price-total-label')}
                  </Typography>
                )}
                {fullWidth && (
                  <Typography variant="h3" textAlign="left" style={{ fontSize: '16px' }}>
                    {t('offer-summary.cruise-fare')}
                  </Typography>
                )}
                <Box>
                  <Price
                    mainPrice={bookingState.getTotalPrice(calculateWithDiscount)}
                    localPrice={bookingState.getTotalPriceLocalCcy(calculateWithDiscount)}
                    discount={bookingState.bookingDetails?.cruiseLine === 'NORWEGIAN' ? '-35%' : ''}
                  />
                  {bookingState.bookingDetails?.cruiseLine === 'NORWEGIAN' && (
                    <Typography variant="body2" textAlign="right" marginTop="8px">
                      {t('offer-summary.price-discount-explanation')}
                    </Typography>
                  )}
                  {bookingState.cabinType && (
                    <Typography variant="body2" textAlign="right" marginTop="8px">
                      <Trans t={t} i18nKey={`booking.departure.mandatory-service-fees`}></Trans>
                    </Typography>
                  )}
                </Box>
                {!fullWidth && shouldPayDeposit && !bookingState.discounts['paytotal'] && (
                  <Stack marginTop={2} spacing={1}>
                    {/* Title */}
                    <Typography variant="h3" textAlign="left" style={{ fontSize: 16, textTransform: 'uppercase' }}>
                      {t('offer-summary.price-details-label')}
                    </Typography>
                    {/* Eloleg */}
                    <PriceBox
                      label={t('offer-summary.price-deposit-label')}
                      mode="deposit"
                      date={depositPaymentDate.toLocaleDateString(i18n.language, {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                      })}
                    />
                    {/* Hatralek */}
                    <PriceBox
                      label={t('offer-summary.price-remainder-label')}
                      mode="remaining"
                      date={lastPaymentDate.toLocaleDateString(i18n.language, {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                      })}
                    />
                  </Stack>
                )}
              </Stack>
            </Grid>
          )}
        </Grid>
        {!fullWidth && props.readonly && props.bookingState.cabinType && __CONFIG__.booking.callbackEnabled && (
          <InfoBox cabinType={props.bookingState.cabinType} />
        )}
        {!fullWidth && props.readonly && <FavoriteBox />}
        {callbackSuccessful && (
          <Alert severity="success" variant="filled" style={{ marginBottom: 15 }}>
            <Typography variant="body1" textAlign="left" fontSize={14} color="#fff">
              {t('booking.departure.callback-dialog.success-text')}
            </Typography>
          </Alert>
        )}
      </Box>
    </>
  );
};
