import './price.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Stack, Grid } from '@mui/material';
import { Codes } from '../../models/Codes';

export class PriceType {
  amount: number;
  currency: string;
  personCount: number | null;
  format: 'short' | 'estimate';

  constructor(a: number, c?: string | null, p?: number | null, d?: 'short' | 'estimate') {
    this.amount = p ? Math.abs(p * a) : a;
    this.currency = c || __CONFIG__.currency.default;
    this.personCount = p || null;
    this.format = d || 'short';
  }
}

export const Price: React.FC<{
  mainPrice?: PriceType;
  localPrice?: PriceType;
  discount?: string;
  label?: {
    text: string;
    background: string;
  };
}> = (props) => {
  const { mainPrice, localPrice } = props;

  // Translation
  const { t } = useTranslation('common');

  //Methods
  function getAmountWithSymbol(a: PriceType): string {
    let result = 'n/a';
    if (a.amount || a.amount === 0) {
      const currency = Codes.currencies.get(a.currency);
      result = `${currency?.prefix}${a.amount.toLocaleString()}${currency?.postfix}`;
    }
    return result;
  }

  // Components
  const MainPrice = () => {
    if (mainPrice) {
      let translationKey =
        mainPrice.personCount === -1
          ? 'main-short'
          : mainPrice.personCount
          ? `main-per-multiple-person-${mainPrice.format}`
          : 'main-per-person';

      return (
        <Trans
          t={t}
          i18nKey={`price.${translationKey}`}
          values={{ price: getAmountWithSymbol(mainPrice), guests: mainPrice.personCount }}
          components={{ label: <span />, amount: <strong /> }}
        ></Trans>
      );
    }
    return <></>;
  };

  const LocalPrice = () => {
    if (localPrice && __CONFIG__.currency.localCurrency) {
      let translationKey = 'local-short';

      if (localPrice.format !== 'short') {
        if (localPrice.personCount === -1) {
          translationKey = 'local-short-estimate';
        } else if (localPrice.personCount) {
          translationKey = `local-per-multiple-person-${localPrice.format}`;
        } else {
          translationKey = `local-per-person-${localPrice.format}`;
        }
      }

      return (
        <Box>
          <Trans
            t={t}
            i18nKey={`price.${translationKey}`}
            values={{ price: getAmountWithSymbol(localPrice), guests: localPrice.personCount }}
            components={{ label: <span />, amount: <strong /> }}
          ></Trans>
        </Box>
      );
    }
    return <></>;
  };

  return (
    <Grid container alignItems="center" justifyContent="end">
      <Grid item>
        {props.discount && (
          <Box mr={1} className="highlight-box">
            {t(`search.filters.promo.options.${props.discount}`, props.discount)}
          </Box>
        )}
      </Grid>

      {props.label && (
        <Grid item>
          <Box mr={1} className="highlight-box" style={{ background: props.label.background }}>
            {props.label.text}
          </Box>
        </Grid>
      )}

      <Grid>
        <Stack className="price" justifyContent="flex-end" alignItems="flex-end" spacing={0.5}>
          <Stack className="price-main" direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
            <MainPrice />
          </Stack>
          <LocalPrice />
        </Stack>
      </Grid>
    </Grid>
  );
};
